export const defaultMenuType = 'menu-default' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
// export const defaultStartPath = '/app/dashboards/default'
// export const searchPath = '/app/pages/miscellaneous/search'
// export const buyUrl = 'https://1.envato.market/nEyZa'

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768


export const defaultLocale = 'en'
export const defaultDirection = 'ltr'
export const locales = [
  {
    "code": "aa",
    "label": "Afar",
    "label_en": "Afar",
    "dir": "ltr"
  },
  {
    "code": "ab",
    "label": "Аҧсуа",
    "label_en": "Abkhazian",
    "dir": "ltr"
  },
  {
    "code": "af",
    "label": "Afrikaans",
    "label_en": "Afrikaans",
    "dir": "ltr"
  },
  {
    "code": "ak",
    "label": "Akana",
    "label_en": "Akan",
    "dir": "ltr"
  },
  {
    "code": "als",
    "label": "Alemannisch",
    "label_en": "Alemannic",
    "dir": "ltr"
  },
  {
    "code": "am",
    "label": "አማርኛ",
    "label_en": "Amharic",
    "dir": "ltr"
  },
  {
    "code": "an",
    "label": "Aragonés",
    "label_en": "Aragonese",
    "dir": "ltr"
  },
  {
    "code": "ang",
    "label": "Angal Heneng",
    "label_en": "Angal",
    "dir": "ltr"
  },
  {
    "code": "ang",
    "label": "Englisc",
    "label_en": "Anglo-Saxon / Old English",
    "dir": "ltr"
  },
  {
    "code": "ar",
    "label": "العربية",
    "label_en": "Arabic",
    "dir": "rtl"
  },
  {
    "code": "arc",
    "label": "ܣܘܪܬ",
    "label_en": "Aramaic",
    "dir": "rtl"
  },
  {
    "code": "as",
    "label": "অসমীয়া",
    "label_en": "Assamese",
    "dir": "ltr"
  },
  {
    "code": "ast",
    "label": "Asturianu",
    "label_en": "Asturian",
    "dir": "ltr"
  },
  {
    "code": "av",
    "label": "Авар",
    "label_en": "Avar",
    "dir": "ltr"
  },
  {
    "code": "awa",
    "label": "Awadhi",
    "label_en": "Awadhi",
    "dir": "ltr"
  },
  {
    "code": "ay",
    "label": "Aymar",
    "label_en": "Aymara",
    "dir": "ltr"
  },
  {
    "code": "az",
    "label": "Azərbaycanca / آذربايجان",
    "label_en": "Azerbaijani",
    "dir": "ltr"
  },
  {
    "code": "ba",
    "label": "Башҡорт",
    "label_en": "Bashkir",
    "dir": "ltr"
  },
  {
    "code": "bar",
    "label": "Boarisch",
    "label_en": "Bavarian",
    "dir": "ltr"
  },
  {
    "code": "bat-smg",
    "label": "Žemaitėška",
    "label_en": "Samogitian",
    "dir": "ltr"
  },
  {
    "code": "bcl",
    "label": "Bikol Central",
    "label_en": "Bikol",
    "dir": "ltr"
  },
  {
    "code": "be",
    "label": "Беларуская",
    "label_en": "Belarusian",
    "dir": "ltr"
  },
  {
    "code": "be-x-old",
    "label": "Беларуская (тарашкевіца)",
    "label_en": "Belarusian (Taraškievica)",
    "dir": "ltr"
  },
  {
    "code": "bg",
    "label": "Български",
    "label_en": "Bulgarian",
    "dir": "ltr"
  },
  {
    "code": "bh",
    "label": "भोजपुरी",
    "label_en": "Bihari",
    "dir": "ltr"
  },
  {
    "code": "bi",
    "label": "Bislama",
    "label_en": "Bislama",
    "dir": "ltr"
  },
  {
    "code": "bm",
    "label": "Bamanankan",
    "label_en": "Bambara",
    "dir": "ltr"
  },
  {
    "code": "bn",
    "label": "বাংলা",
    "label_en": "Bengali",
    "dir": "ltr"
  },
  {
    "code": "bo",
    "label": "བོད་ཡིག / Bod skad",
    "label_en": "Tibetan",
    "dir": "ltr"
  },
  {
    "code": "bpy",
    "label": "ইমার ঠার/বিষ্ণুপ্রিয়া মণিপুরী",
    "label_en": "Bishnupriya Manipuri",
    "dir": "ltr"
  },
  {
    "code": "br",
    "label": "Brezhoneg",
    "label_en": "Breton",
    "dir": "ltr"
  },
  {
    "code": "bs",
    "label": "Bosanski",
    "label_en": "Bosnian",
    "dir": "ltr"
  },
  {
    "code": "bug",
    "label": "ᨅᨔ ᨕᨘᨁᨗ / Basa Ugi",
    "label_en": "Buginese",
    "dir": "ltr"
  },
  {
    "code": "bxr",
    "label": "Буряад хэлэн",
    "label_en": "Buriat (Russia)",
    "dir": "ltr"
  },
  {
    "code": "ca",
    "label": "Català",
    "label_en": "Catalan",
    "dir": "ltr"
  },
  {
    "code": "cdo",
    "label": "Mìng-dĕ̤ng-ngṳ̄ / 閩東語",
    "label_en": "Min Dong Chinese",
    "dir": "ltr"
  },
  {
    "code": "ce",
    "label": "Нохчийн",
    "label_en": "Chechen",
    "dir": "ltr"
  },
  {
    "code": "ceb",
    "label": "Sinugboanong Binisaya",
    "label_en": "Cebuano",
    "dir": "ltr"
  },
  {
    "code": "ch",
    "label": "Chamoru",
    "label_en": "Chamorro",
    "dir": "ltr"
  },
  {
    "code": "cho",
    "label": "Choctaw",
    "label_en": "Choctaw",
    "dir": "ltr"
  },
  {
    "code": "chr",
    "label": "ᏣᎳᎩ",
    "label_en": "Cherokee",
    "dir": "ltr"
  },
  {
    "code": "chy",
    "label": "Tsetsêhestâhese",
    "label_en": "Cheyenne",
    "dir": "ltr"
  },
  {
    "code": "co",
    "label": "Corsu",
    "label_en": "Corsican",
    "dir": "ltr"
  },
  {
    "code": "cr",
    "label": "Nehiyaw",
    "label_en": "Cree",
    "dir": "ltr"
  },
  {
    "code": "cs",
    "label": "Česky",
    "label_en": "Czech",
    "dir": "ltr"
  },
  {
    "code": "csb",
    "label": "Kaszëbsczi",
    "label_en": "Kashubian",
    "dir": "ltr"
  },
  {
    "code": "cu",
    "label": "словѣньскъ / slověnĭskŭ",
    "label_en": "Old Church Slavonic / Old Bulgarian",
    "dir": "ltr"
  },
  {
    "code": "cv",
    "label": "Чăваш",
    "label_en": "Chuvash",
    "dir": "ltr"
  },
  {
    "code": "cy",
    "label": "Cymraeg",
    "label_en": "Welsh",
    "dir": "ltr"
  },
  {
    "code": "da",
    "label": "Dansk",
    "label_en": "Danish",
    "dir": "ltr"
  },
  {
    "code": "de",
    "label": "Deutsch",
    "label_en": "German",
    "dir": "ltr"
  },
  {
    "code": "diq",
    "label": "Zazaki",
    "label_en": "Dimli",
    "dir": "ltr"
  },
  {
    "code": "dsb",
    "label": "Dolnoserbski",
    "label_en": "Lower Sorbian",
    "dir": "ltr"
  },
  {
    "code": "dv",
    "label": "ދިވެހިބަސް",
    "label_en": "Divehi",
    "dir": "rtl"
  },
  {
    "code": "dz",
    "label": "ཇོང་ཁ",
    "label_en": "Dzongkha",
    "dir": "ltr"
  },
  {
    "code": "ee",
    "label": "Ɛʋɛ",
    "label_en": "Ewe",
    "dir": "ltr"
  },
  {
    "code": "el",
    "label": "Ελληνικά",
    "label_en": "Greek",
    "dir": "ltr"
  },
  {
    "code": "en",
    "label": "English",
    "label_en": "English",
    "dir": "ltr"
  },
  {
    "code": "eo",
    "label": "Esperanto",
    "label_en": "Esperanto",
    "dir": "ltr"
  },
  {
    "code": "es",
    "label": "Español",
    "label_en": "Spanish",
    "dir": "ltr"
  },
  {
    "code": "et",
    "label": "Eesti",
    "label_en": "Estonian",
    "dir": "ltr"
  },
  {
    "code": "eu",
    "label": "Euskara",
    "label_en": "Basque",
    "dir": "ltr"
  },
  {
    "code": "ext",
    "label": "Estremeñu",
    "label_en": "Extremaduran",
    "dir": "ltr"
  },
  {
    "code": "fa",
    "label": "فارسی",
    "label_en": "Persian",
    "dir": "rtl"
  },
  {
    "code": "ff",
    "label": "Fulfulde",
    "label_en": "Peul",
    "dir": "ltr"
  },
  {
    "code": "fi",
    "label": "Suomi",
    "label_en": "Finnish",
    "dir": "ltr"
  },
  {
    "code": "fiu-vro",
    "label": "Võro",
    "label_en": "Võro",
    "dir": "ltr"
  },
  {
    "code": "fj",
    "label": "Na Vosa Vakaviti",
    "label_en": "Fijian",
    "dir": "ltr"
  },
  {
    "code": "fo",
    "label": "Føroyskt",
    "label_en": "Faroese",
    "dir": "ltr"
  },
  {
    "code": "fr",
    "label": "Français",
    "label_en": "French",
    "dir": "ltr"
  },
  {
    "code": "frp",
    "label": "Arpitan / francoprovençal",
    "label_en": "Arpitan / Franco-Provençal",
    "dir": "ltr"
  },
  {
    "code": "fur",
    "label": "Furlan",
    "label_en": "Friulian",
    "dir": "ltr"
  },
  {
    "code": "fy",
    "label": "Frysk",
    "label_en": "West Frisian",
    "dir": "ltr"
  },
  {
    "code": "ga",
    "label": "Gaeilge",
    "label_en": "Irish",
    "dir": "ltr"
  },
  {
    "code": "gan",
    "label": "贛語",
    "label_en": "Gan Chinese",
    "dir": "ltr"
  },
  {
    "code": "gbm",
    "label": "गढ़वळी",
    "label_en": "Garhwali",
    "dir": "ltr"
  },
  {
    "code": "gd",
    "label": "Gàidhlig",
    "label_en": "Scottish Gaelic",
    "dir": "ltr"
  },
  {
    "code": "gil",
    "label": "Taetae ni kiribati",
    "label_en": "Gilbertese",
    "dir": "ltr"
  },
  {
    "code": "gl",
    "label": "Galego",
    "label_en": "Galician",
    "dir": "ltr"
  },
  {
    "code": "gn",
    "label": "Avañe'ẽ",
    "label_en": "Guarani",
    "dir": "ltr"
  },
  {
    "code": "got",
    "label": "gutisk",
    "label_en": "Gothic",
    "dir": "ltr"
  },
  {
    "code": "gu",
    "label": "ગુજરાતી",
    "label_en": "Gujarati",
    "dir": "ltr"
  },
  {
    "code": "gv",
    "label": "Gaelg",
    "label_en": "Manx",
    "dir": "ltr"
  },
  {
    "code": "ha",
    "label": "هَوُسَ",
    "label_en": "Hausa",
    "dir": "rtl"
  },
  {
    "code": "hak",
    "label": "客家語/Hak-kâ-ngî",
    "label_en": "Hakka Chinese",
    "dir": "ltr"
  },
  {
    "code": "haw",
    "label": "Hawai`i",
    "label_en": "Hawaiian",
    "dir": "ltr"
  },
  {
    "code": "he",
    "label": "עברית",
    "label_en": "Hebrew",
    "dir": "rtl"
  },
  {
    "code": "hi",
    "label": "हिन्दी",
    "label_en": "Hindi",
    "dir": "ltr"
  },
  {
    "code": "ho",
    "label": "Hiri Motu",
    "label_en": "Hiri Motu",
    "dir": "ltr"
  },
  {
    "code": "hr",
    "label": "Hrvatski",
    "label_en": "Croatian",
    "dir": "ltr"
  },
  {
    "code": "ht",
    "label": "Krèyol ayisyen",
    "label_en": "Haitian",
    "dir": "ltr"
  },
  {
    "code": "hu",
    "label": "Magyar",
    "label_en": "Hungarian",
    "dir": "ltr"
  },
  {
    "code": "hy",
    "label": "Հայերեն",
    "label_en": "Armenian",
    "dir": "ltr"
  },
  {
    "code": "hz",
    "label": "Otsiherero",
    "label_en": "Herero",
    "dir": "ltr"
  },
  {
    "code": "ia",
    "label": "Interlingua",
    "label_en": "Interlingua",
    "dir": "ltr"
  },
  {
    "code": "id",
    "label": "Bahasa Indonesia",
    "label_en": "Indonesian",
    "dir": "ltr"
  },
  {
    "code": "ie",
    "label": "Interlingue",
    "label_en": "Interlingue",
    "dir": "ltr"
  },
  {
    "code": "ig",
    "label": "Igbo",
    "label_en": "Igbo",
    "dir": "ltr"
  },
  {
    "code": "ii",
    "label": "ꆇꉙ / 四川彝语",
    "label_en": "Sichuan Yi",
    "dir": "ltr"
  },
  {
    "code": "ik",
    "label": "Iñupiak",
    "label_en": "Inupiak",
    "dir": "ltr"
  },
  {
    "code": "ilo",
    "label": "Ilokano",
    "label_en": "Ilokano",
    "dir": "ltr"
  },
  {
    "code": "inh",
    "label": "ГӀалгӀай",
    "label_en": "Ingush",
    "dir": "ltr"
  },
  {
    "code": "io",
    "label": "Ido",
    "label_en": "Ido",
    "dir": "ltr"
  },
  {
    "code": "is",
    "label": "Íslenska",
    "label_en": "Icelandic",
    "dir": "ltr"
  },
  {
    "code": "it",
    "label": "Italiano",
    "label_en": "Italian",
    "dir": "ltr"
  },
  {
    "code": "iu",
    "label": "ᐃᓄᒃᑎᑐᑦ",
    "label_en": "Inuktitut",
    "dir": "ltr"
  },
  {
    "code": "ja",
    "label": "日本語",
    "label_en": "Japanese",
    "dir": "ltr"
  },
  {
    "code": "jbo",
    "label": "Lojban",
    "label_en": "Lojban",
    "dir": "ltr"
  },
  {
    "code": "jv",
    "label": "Basa Jawa",
    "label_en": "Javanese",
    "dir": "ltr"
  },
  {
    "code": "ka",
    "label": "ქართული",
    "label_en": "Georgian",
    "dir": "ltr"
  },
  {
    "code": "kg",
    "label": "KiKongo",
    "label_en": "Kongo",
    "dir": "ltr"
  },
  {
    "code": "ki",
    "label": "Gĩkũyũ",
    "label_en": "Kikuyu",
    "dir": "ltr"
  },
  {
    "code": "kj",
    "label": "Kuanyama",
    "label_en": "Kuanyama",
    "dir": "ltr"
  },
  {
    "code": "kk",
    "label": "Қазақша",
    "label_en": "Kazakh",
    "dir": "ltr"
  },
  {
    "code": "kl",
    "label": "Kalaallisut",
    "label_en": "Greenlandic",
    "dir": "ltr"
  },
  {
    "code": "km",
    "label": "ភាសាខ្មែរ",
    "label_en": "Cambodian",
    "dir": "ltr"
  },
  {
    "code": "kn",
    "label": "ಕನ್ನಡ",
    "label_en": "Kannada",
    "dir": "ltr"
  },
  {
    "code": "khw",
    "label": "کھوار",
    "label_en": "Khowar",
    "dir": "rtl"
  },
  {
    "code": "ko",
    "label": "한국어",
    "label_en": "Korean",
    "dir": "ltr"
  },
  {
    "code": "kr",
    "label": "Kanuri",
    "label_en": "Kanuri",
    "dir": "ltr"
  },
  {
    "code": "ks",
    "label": "कश्मीरी / كشميري",
    "label_en": "Kashmiri",
    "dir": "rtl"
  },
  {
    "code": "ksh",
    "label": "Ripoarisch",
    "label_en": "Ripuarian",
    "dir": "ltr"
  },
  {
    "code": "ku",
    "label": "Kurdî / كوردی",
    "label_en": "Kurdish",
    "dir": "rtl"
  },
  {
    "code": "kv",
    "label": "Коми",
    "label_en": "Komi",
    "dir": "ltr"
  },
  {
    "code": "kw",
    "label": "Kernewek",
    "label_en": "Cornish",
    "dir": "ltr"
  },
  {
    "code": "ky",
    "label": "Kırgızca / Кыргызча",
    "label_en": "Kirghiz",
    "dir": "ltr"
  },
  {
    "code": "la",
    "label": "Latina",
    "label_en": "Latin",
    "dir": "ltr"
  },
  {
    "code": "lad",
    "label": "Dzhudezmo / Djudeo-Espanyol",
    "label_en": "Ladino / Judeo-Spanish",
    "dir": "ltr"
  },
  {
    "code": "lan",
    "label": "Leb Lango / Luo",
    "label_en": "Lango",
    "dir": "ltr"
  },
  {
    "code": "lb",
    "label": "Lëtzebuergesch",
    "label_en": "Luxembourgish",
    "dir": "ltr"
  },
  {
    "code": "lg",
    "label": "Luganda",
    "label_en": "Ganda",
    "dir": "ltr"
  },
  {
    "code": "li",
    "label": "Limburgs",
    "label_en": "Limburgian",
    "dir": "ltr"
  },
  {
    "code": "lij",
    "label": "Líguru",
    "label_en": "Ligurian",
    "dir": "ltr"
  },
  {
    "code": "lmo",
    "label": "Lumbaart",
    "label_en": "Lombard",
    "dir": "ltr"
  },
  {
    "code": "ln",
    "label": "Lingála",
    "label_en": "Lingala",
    "dir": "ltr"
  },
  {
    "code": "lo",
    "label": "ລາວ / Pha xa lao",
    "label_en": "Laotian",
    "dir": "ltr"
  },
  {
    "code": "lzz",
    "label": "Lazuri / ლაზური",
    "label_en": "Laz",
    "dir": "ltr"
  },
  {
    "code": "lt",
    "label": "Lietuvių",
    "label_en": "Lithuanian",
    "dir": "ltr"
  },
  {
    "code": "lv",
    "label": "Latviešu",
    "label_en": "Latvian",
    "dir": "ltr"
  },
  {
    "code": "map-bms",
    "label": "Basa Banyumasan",
    "label_en": "Banyumasan",
    "dir": "ltr"
  },
  {
    "code": "mg",
    "label": "Malagasy",
    "label_en": "Malagasy",
    "dir": "ltr"
  },
  {
    "code": "man",
    "label": "官話/官话",
    "label_en": "Mandarin",
    "dir": "ltr"
  },
  {
    "code": "mh",
    "label": "Kajin Majel / Ebon",
    "label_en": "Marshallese",
    "dir": "ltr"
  },
  {
    "code": "mi",
    "label": "Māori",
    "label_en": "Maori",
    "dir": "ltr"
  },
  {
    "code": "min",
    "label": "Minangkabau",
    "label_en": "Minangkabau",
    "dir": "ltr"
  },
  {
    "code": "mk",
    "label": "Македонски",
    "label_en": "Macedonian",
    "dir": "ltr"
  },
  {
    "code": "ml",
    "label": "മലയാളം",
    "label_en": "Malayalam",
    "dir": "ltr"
  },
  {
    "code": "mn",
    "label": "Монгол",
    "label_en": "Mongolian",
    "dir": "ltr"
  },
  {
    "code": "mo",
    "label": "Moldovenească",
    "label_en": "Moldovan",
    "dir": "ltr"
  },
  {
    "code": "mr",
    "label": "मराठी",
    "label_en": "Marathi",
    "dir": "ltr"
  },
  {
    "code": "mrh",
    "label": "Mara",
    "label_en": "Mara",
    "dir": "ltr"
  },
  {
    "code": "ms",
    "label": "Bahasa Melayu",
    "label_en": "Malay",
    "dir": "ltr"
  },
  {
    "code": "mt",
    "label": "bil-Malti",
    "label_en": "Maltese",
    "dir": "ltr"
  },
  {
    "code": "mus",
    "label": "Mvskoke",
    "label_en": "Creek / Muskogee",
    "dir": "ltr"
  },
  {
    "code": "mwl",
    "label": "Mirandés",
    "label_en": "Mirandese",
    "dir": "ltr"
  },
  {
    "code": "my",
    "label": "Myanmasa",
    "label_en": "Burmese",
    "dir": "ltr"
  },
  {
    "code": "na",
    "label": "Dorerin Naoero",
    "label_en": "Nauruan",
    "dir": "ltr"
  },
  {
    "code": "nah",
    "label": "Nahuatl",
    "label_en": "Nahuatl",
    "dir": "ltr"
  },
  {
    "code": "nap",
    "label": "Nnapulitano",
    "label_en": "Neapolitan",
    "dir": "ltr"
  },
  {
    "code": "nd",
    "label": "Sindebele",
    "label_en": "North Ndebele",
    "dir": "ltr"
  },
  {
    "code": "nds",
    "label": "Plattdüütsch",
    "label_en": "Low German / Low Saxon",
    "dir": "ltr"
  },
  {
    "code": "nds-nl",
    "label": "Nedersaksisch",
    "label_en": "Dutch Low Saxon",
    "dir": "ltr"
  },
  {
    "code": "ne",
    "label": "नेपाली",
    "label_en": "Nepali",
    "dir": "ltr"
  },
  {
    "code": "new",
    "label": "नेपालभाषा / Newah Bhaye",
    "label_en": "Newar",
    "dir": "ltr"
  },
  {
    "code": "ng",
    "label": "Oshiwambo",
    "label_en": "Ndonga",
    "dir": "ltr"
  },
  {
    "code": "nl",
    "label": "Nederlands",
    "label_en": "Dutch",
    "dir": "ltr"
  },
  {
    "code": "nn",
    "label": "Norsk (nynorsk)",
    "label_en": "Norwegian Nynorsk",
    "dir": "ltr"
  },
  {
    "code": "no",
    "label": "Norsk (bokmål / riksmål)",
    "label_en": "Norwegian",
    "dir": "ltr"
  },
  {
    "code": "nr",
    "label": "isiNdebele",
    "label_en": "South Ndebele",
    "dir": "ltr"
  },
  {
    "code": "nso",
    "label": "Sesotho sa Leboa / Sepedi",
    "label_en": "Northern Sotho",
    "dir": "ltr"
  },
  {
    "code": "nrm",
    "label": "Nouormand / Normaund",
    "label_en": "Norman",
    "dir": "ltr"
  },
  {
    "code": "nv",
    "label": "Diné bizaad",
    "label_en": "Navajo",
    "dir": "ltr"
  },
  {
    "code": "ny",
    "label": "Chi-Chewa",
    "label_en": "Chichewa",
    "dir": "ltr"
  },
  {
    "code": "oc",
    "label": "Occitan",
    "label_en": "Occitan",
    "dir": "ltr"
  },
  {
    "code": "oj",
    "label": "ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin",
    "label_en": "Ojibwa",
    "dir": "ltr"
  },
  {
    "code": "om",
    "label": "Oromoo",
    "label_en": "Oromo",
    "dir": "ltr"
  },
  {
    "code": "or",
    "label": "ଓଡ଼ିଆ",
    "label_en": "Oriya",
    "dir": "ltr"
  },
  {
    "code": "os",
    "label": "Иронау",
    "label_en": "Ossetian / Ossetic",
    "dir": "ltr"
  },
  {
    "code": "pa",
    "label": "ਪੰਜਾਬੀ / पंजाबी / پنجابي",
    "label_en": "Panjabi / Punjabi",
    "dir": "ltr"
  },
  {
    "code": "pag",
    "label": "Pangasinan",
    "label_en": "Pangasinan",
    "dir": "ltr"
  },
  {
    "code": "pam",
    "label": "Kapampangan",
    "label_en": "Kapampangan",
    "dir": "ltr"
  },
  {
    "code": "pap",
    "label": "Papiamentu",
    "label_en": "Papiamentu",
    "dir": "ltr"
  },
  {
    "code": "pdc",
    "label": "Deitsch",
    "label_en": "Pennsylvania German",
    "dir": "ltr"
  },
  {
    "code": "pi",
    "label": "Pāli / पाऴि",
    "label_en": "Pali",
    "dir": "ltr"
  },
  {
    "code": "pih",
    "label": "Norfuk",
    "label_en": "Norfolk",
    "dir": "ltr"
  },
  {
    "code": "pl",
    "label": "Polski",
    "label_en": "Polish",
    "dir": "ltr"
  },
  {
    "code": "pms",
    "label": "Piemontèis",
    "label_en": "Piedmontese",
    "dir": "ltr"
  },
  {
    "code": "ps",
    "label": "پښتو",
    "label_en": "Pashto",
    "dir": "rtl"
  },
  {
    "code": "pt",
    "label": "Português",
    "label_en": "Portuguese",
    "dir": "ltr"
  },
  {
    "code": "qu",
    "label": "Runa Simi",
    "label_en": "Quechua",
    "dir": "ltr"
  },
  {
    "code": "rm",
    "label": "Rumantsch",
    "label_en": "Raeto Romance",
    "dir": "ltr"
  },
  {
    "code": "rmy",
    "label": "Romani / रोमानी",
    "label_en": "Romani",
    "dir": "ltr"
  },
  {
    "code": "rn",
    "label": "Kirundi",
    "label_en": "Kirundi",
    "dir": "ltr"
  },
  {
    "code": "ro",
    "label": "Română",
    "label_en": "Romanian",
    "dir": "ltr"
  },
  {
    "code": "roa-rup",
    "label": "Armâneashti",
    "label_en": "Aromanian",
    "dir": "ltr"
  },
  {
    "code": "ru",
    "label": "Русский",
    "label_en": "Russian",
    "dir": "ltr"
  },
  {
    "code": "rw",
    "label": "Kinyarwandi",
    "label_en": "Rwandi",
    "dir": "ltr"
  },
  {
    "code": "sa",
    "label": "संस्कृतम्",
    "label_en": "Sanskrit",
    "dir": "ltr"
  },
  {
    "code": "sc",
    "label": "Sardu",
    "label_en": "Sardinian",
    "dir": "ltr"
  },
  {
    "code": "scn",
    "label": "Sicilianu",
    "label_en": "Sicilian",
    "dir": "ltr"
  },
  {
    "code": "sco",
    "label": "Scots",
    "label_en": "Scots",
    "dir": "ltr"
  },
  {
    "code": "sd",
    "label": "सिनधि",
    "label_en": "Sindhi",
    "dir": "ltr"
  },
  {
    "code": "se",
    "label": "Davvisámegiella",
    "label_en": "Northern Sami",
    "dir": "ltr"
  },
  {
    "code": "sg",
    "label": "Sängö",
    "label_en": "Sango",
    "dir": "ltr"
  },
  {
    "code": "sh",
    "label": "Srpskohrvatski / Српскохрватски",
    "label_en": "Serbo-Croatian",
    "dir": "ltr"
  },
  {
    "code": "si",
    "label": "සිංහල",
    "label_en": "Sinhalese",
    "dir": "ltr"
  },
  {
    "code": "simple",
    "label": "Simple English",
    "label_en": "Simple English",
    "dir": "ltr"
  },
  {
    "code": "sk",
    "label": "Slovenčina",
    "label_en": "Slovak",
    "dir": "ltr"
  },
  {
    "code": "sl",
    "label": "Slovenščina",
    "label_en": "Slovenian",
    "dir": "ltr"
  },
  {
    "code": "sm",
    "label": "Gagana Samoa",
    "label_en": "Samoan",
    "dir": "ltr"
  },
  {
    "code": "sn",
    "label": "chiShona",
    "label_en": "Shona",
    "dir": "ltr"
  },
  {
    "code": "so",
    "label": "Soomaaliga",
    "label_en": "Somalia",
    "dir": "ltr"
  },
  {
    "code": "sq",
    "label": "Shqip",
    "label_en": "Albanian",
    "dir": "ltr"
  },
  {
    "code": "sr",
    "label": "Српски",
    "label_en": "Serbian",
    "dir": "ltr"
  },
  {
    "code": "ss",
    "label": "SiSwati",
    "label_en": "Swati",
    "dir": "ltr"
  },
  {
    "code": "st",
    "label": "Sesotho",
    "label_en": "Southern Sotho",
    "dir": "ltr"
  },
  {
    "code": "su",
    "label": "Basa Sunda",
    "label_en": "Sundanese",
    "dir": "ltr"
  },
  {
    "code": "sv",
    "label": "Svenska",
    "label_en": "Swedish",
    "dir": "ltr"
  },
  {
    "code": "sw",
    "label": "Kiswahili",
    "label_en": "Swahili",
    "dir": "ltr"
  },
  {
    "code": "ta",
    "label": "தமிழ்",
    "label_en": "Tamil",
    "dir": "ltr"
  },
  {
    "code": "te",
    "label": "తెలుగు",
    "label_en": "Telugu",
    "dir": "ltr"
  },
  {
    "code": "tet",
    "label": "Tetun",
    "label_en": "Tetum",
    "dir": "ltr"
  },
  {
    "code": "tg",
    "label": "Тоҷикӣ",
    "label_en": "Tajik",
    "dir": "ltr"
  },
  {
    "code": "th",
    "label": "ไทย / Phasa Thai",
    "label_en": "Thai",
    "dir": "ltr"
  },
  {
    "code": "ti",
    "label": "ትግርኛ",
    "label_en": "Tigrinya",
    "dir": "ltr"
  },
  {
    "code": "tk",
    "label": "Туркмен / تركمن",
    "label_en": "Turkmen",
    "dir": "ltr"
  },
  {
    "code": "tl",
    "label": "Tagalog",
    "label_en": "Tagalog",
    "dir": "ltr"
  },
  {
    "code": "tlh",
    "label": "tlhIngan-Hol",
    "label_en": "Klingon",
    "dir": "ltr"
  },
  {
    "code": "tn",
    "label": "Setswana",
    "label_en": "Tswana",
    "dir": "ltr"
  },
  {
    "code": "to",
    "label": "Lea Faka-Tonga",
    "label_en": "Tonga",
    "dir": "ltr"
  },
  {
    "code": "tpi",
    "label": "Tok Pisin",
    "label_en": "Tok Pisin",
    "dir": "ltr"
  },
  {
    "code": "tr",
    "label": "Türkçe",
    "label_en": "Turkish",
    "dir": "ltr"
  },
  {
    "code": "ts",
    "label": "Xitsonga",
    "label_en": "Tsonga",
    "dir": "ltr"
  },
  {
    "code": "tt",
    "label": "Tatarça",
    "label_en": "Tatar",
    "dir": "ltr"
  },
  {
    "code": "tum",
    "label": "chiTumbuka",
    "label_en": "Tumbuka",
    "dir": "ltr"
  },
  {
    "code": "tw",
    "label": "Twi",
    "label_en": "Twi",
    "dir": "ltr"
  },
  {
    "code": "ty",
    "label": "Reo Mā`ohi",
    "label_en": "Tahitian",
    "dir": "ltr"
  },
  {
    "code": "udm",
    "label": "Удмурт кыл",
    "label_en": "Udmurt",
    "dir": "ltr"
  },
  {
    "code": "ug",
    "label": "Uyƣurqə / ئۇيغۇرچە",
    "label_en": "Uyghur",
    "dir": "ltr"
  },
  {
    "code": "uk",
    "label": "Українська",
    "label_en": "Ukrainian",
    "dir": "ltr"
  },
  {
    "code": "ur",
    "label": "اردو",
    "label_en": "Urdu",
    "dir": "rtl"
  },
  {
    "code": "uz",
    "label": "Ўзбек",
    "label_en": "Uzbek",
    "dir": "ltr"
  },
  {
    "code": "ve",
    "label": "Tshivenḓa",
    "label_en": "Venda",
    "dir": "ltr"
  },
  {
    "code": "vi",
    "label": "Việtnam",
    "label_en": "Vietnamese",
    "dir": "ltr"
  },
  {
    "code": "vec",
    "label": "Vèneto",
    "label_en": "Venetian",
    "dir": "ltr"
  },
  {
    "code": "vls",
    "label": "West-Vlaoms",
    "label_en": "West Flemish",
    "dir": "ltr"
  },
  {
    "code": "vo",
    "label": "Volapük",
    "label_en": "Volapük",
    "dir": "ltr"
  },
  {
    "code": "wa",
    "label": "Walon",
    "label_en": "Walloon",
    "dir": "ltr"
  },
  {
    "code": "war",
    "label": "Winaray / Binisaya Lineyte-Samarnon",
    "label_en": "Waray / Samar-Leyte Visayan",
    "dir": "ltr"
  },
  {
    "code": "wo",
    "label": "Wollof",
    "label_en": "Wolof",
    "dir": "ltr"
  },
  {
    "code": "xal",
    "label": "Хальмг",
    "label_en": "Kalmyk",
    "dir": "ltr"
  },
  {
    "code": "xh",
    "label": "isiXhosa",
    "label_en": "Xhosa",
    "dir": "ltr"
  },
  {
    "code": "xmf",
    "label": "მარგალური",
    "label_en": "Megrelian",
    "dir": "ltr"
  },
  {
    "code": "yi",
    "label": "ייִדיש",
    "label_en": "Yiddish",
    "dir": "rtl"
  },
  {
    "code": "yo",
    "label": "Yorùbá",
    "label_en": "Yoruba",
    "dir": "ltr"
  },
  {
    "code": "za",
    "label": "Cuengh / Tôô / 壮语",
    "label_en": "Zhuang",
    "dir": "ltr"
  },
  {
    "code": "zh",
    "label": "中文",
    "label_en": "Chinese",
    "dir": "ltr"
  },
  {
    "code": "zh-classical",
    "label": "文言",
    "label_en": "Classical Chinese",
    "dir": "ltr"
  },
  {
    "code": "zh-min-nan",
    "label": "Bân-lâm-gú",
    "label_en": "Minnan",
    "dir": "ltr"
  },
  {
    "code": "zh-yue",
    "label": "粵語 / 粤语",
    "label_en": "Cantonese",
    "dir": "ltr"
  },
  {
    "code": "zu",
    "label": "isiZulu",
    "label_en": "Zulu",
    "dir": "ltr"
  }
]

// export const firebaseConfig = {
//   apiKey: "AIzaSyDe94G7L-3soXVSpVbsYlB5DfYH2L91aTU",
//   authDomain: "piaf-vue-login.firebaseapp.com",
//   databaseURL: "https://piaf-vue-login.firebaseio.com",
//   projectId: "piaf-vue-login",
//   storageBucket: "piaf-vue-login.appspot.com",
//   messagingSenderId: "557576321564",
//   appId: "1:557576321564:web:bc2ce73477aff5c2197dd9"
// };


export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profile-pic-l.jpg',
  date: 'Last seen today 15:24'
}

export const isDemo = true
export const themeRadiusStorageKey = 'theme_radius'
export const defaultColor = 'light.green'
export const colors = [
  // 'light.purple',
  // 'dark.purple',
  // 'light.blue',
  // 'dark.blue',
  'light.green',
  'dark.green',
  // 'light.orange',
  // 'dark.orange',
  // 'light.red',
  // 'dark.red'
]
