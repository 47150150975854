<template>
  <div class="d-flex justify-content-center mt-1 h-100">
    <b-card
      header-class="card-settings__header card-header-border h-100"
      class="my-border"
      style="width: 450px"
    >
      <template #header>
        <div class="font-weight-bold font-small-3 my-auto">Settings</div>
      </template>

      <b-form>
        <b-row class="align-items-center m-0 h-100 my-2">
          <b-col lg="6" sm="6" class="w-60">
            Language
          </b-col>
          <b-col lg="6" sm="6" class="w-40">
            <v-select
              :options="localeOptions"
              :reduce="(locale) => locale.code"
              lable="label"
              v-model="language"
              :clearable="false"
            />
          </b-col>
        </b-row>

        <b-row class="align-items-center m-0 h-100 my-2">
          <b-col lg="6" sm="6" class="w-60">
            Display Currency
          </b-col>
          <b-col lg="6" sm="6" class="w-40">
            <v-select
              :options="currencyOptions"
              v-model="display_currency"
              :clearable="false"
            />
          </b-col>
        </b-row>

        <div class="text-center mb-2">
          <b-button variant="flat-dark" class="border" @click="saveSettings"
            >Save</b-button
          >
        </div>
      </b-form>
    </b-card>
  </div>
</template>
<script>
import axios from "@axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { locales } from "@/constant/config";
import { notifyError, notifySuccess } from "@/@core/utils";

export default {
  components: {
    vSelect,
  },

  data() {
    return {
      user: {},
      language: "",
      display_currency: "",
      localeOptions: [],
      currencyOptions: ["USD", "EUR", "GBP", "JPY"],
    };
  },

  methods: {
    saveSettings() {
      let user = {
        dashboard_language: this.language,
        display_currency: this.display_currency,
        email_language: this.user.email_language,
        id: this.user.id,
      };
      axios
        .post(
          `${localStorage.getItem("VUE_APP_API_URL")}/dashboard/update_user`,
          { user }
        )
        .then((res) => {})
        .then((res) => {
          localStorage.setItem(
            "userData",
            JSON.stringify({
              ...this.user,
              dashboard_language: this.language,
              display_currency: this.display_currency,
            })
          );
          notifySuccess(
            "Success",
            "You successfully update language and display currency"
          );
        })
        .catch((err) => {
          notifyError("Error", err.response.data.errors.message);
        });
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("userData"));
    this.language = this.user.dashboard_language;
    this.display_currency = this.user.display_currency;
    axios
      .get(
        `${localStorage.getItem(
          "VUE_APP_API_URL"
        )}//public_site_settings/company?recursive`
      )
      .then((res) => {
        let company = res.data.settings;
        if (company) {
          let langs = company.languages || [];
          this.localeOptions = locales.filter((locale) =>
            langs.includes(locale.code)
          );
        }
      });
  },
};
</script>
